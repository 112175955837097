.generic-tooltip {
  display: inline-block;
  padding: 2px;
}

.popover {
  white-space: pre-wrap;
}

.bs-popover-left > .arrow {
  right: -0.5rem;
}

.bs-popover-right > .arrow {
  left: -0.5rem;
}

.bs-popover-bottom > .arrow {
  top: -0.5rem;
}

.bs-popover-top > .arrow {
  bottom: -0.5rem;
}

.popover-dark {
  .popover-body {
    color: #fff;
    background: #4F525C;
    border-color: #4F525C;

    > * {
      color: #fff !important;
    }
  }

  &.bs-popover-left {
    > .arrow::after {
      border-left-color: #4F525C;
    }
  }

  &.bs-popover-right {
    > .arrow::after {
      border-right-color: #4F525C;
    }
  }

  &.bs-popover-bottom {
    > .arrow::after {
      border-bottom-color: #4F525C;
    }
  }

  &.bs-popover-top {
    > .arrow::after {
      border-top-color: #4F525C;
    }
  }
}