.InfoSlider {
  .SlickNavigation {
    display: flex;
    margin: 1em 0 0.5em;

    .SlickPrev,
    .SlickNext {
      border: none;
      background: transparent;
      padding: 1px 5px;
      outline: none;
    }

    .SlickDot {
      width: 16px;
      height: 16px;
      text-align: center;
      cursor: pointer;

      &::after {
        content: "";
        width: 8px;
        height: 8px;
        border-radius: 50%;
        display: inline-block;
        margin: 9px 0;
        background: #B6B9C9;
      }

      &.active::after {
        content: "";
        width: 12px;
        height: 12px;
        margin: 7px 0;
        border-radius: 50%;
        display: inline-block;

        background: #267EFF;
      }
    }
  }
}