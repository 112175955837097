.landing-properties {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.landing-property-card {
  width: 100%;
  flex-grow: 1;
  background: #FFFFFF;
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.15);
  border-radius: 6px;
  font-family: "Inter";
  position: relative;
  overflow: hidden;
  margin-bottom: 2rem;
  font-size: 1.5rem;
  
  .landing-property-image {
    width: 100%;
    padding-top: 65.25%;
    background-size: cover;
    background-position: center;
    border-bottom-left-radius: 6px;
    border-bottom-right-radius: 6px;
  }

  .landing-property-card-inner {
    padding: 1.3rem 1.65rem 1.05rem 1.65rem;
  }

  p {
    color: #033863;
  }

  span {
    color: #1AB5FA;
  }

  .price {
    display: flex;
    align-items: center;

    span {
      font-weight: bold;
      font-size: 1.1em;
      line-height: 1.5rem;
    }

    .price-monthly {
      margin-left: 11px;
      opacity: 0.6;
      font-size: 0.75em;
    }

    svg {
      margin-left: auto;
      width: 1.1em;
      height: 1.1em;
    }
  }

  .term {
    margin-top: 0.8em;
  }

  .payment,
  .owed {
    margin-top: 2px;
  }

  .term,
  .payment,
  .owed {
    font-size: 0.7em;

    span {
      font-weight: 500;
    }
  }

  .action {
    display: inline-block;
    margin-top: 1.5em;
    font-family: "Gotham";
    font-size: 0.9em;

    span {
      margin-right: 6px;
    }

    svg {
      width: 0.95em;
      height: 0.75em;
    }
  }

  .profits {
    display: flex;
    line-height: 1em;
    justify-content: space-between;
    flex-wrap: wrap;

    svg {
      width: 1em;
      height: 1em;
      margin-bottom: 0.1em;
    }

    div {
      display: flex;
      flex-direction: column;
      font-weight: 700;
      font-size: 0.7em;
      margin-top: 1.1em;

      &.traditional-profits {
        span {
          color: #6888A1;
          font-weight: 400;
        }
      }

      &:nth-child(1) {
        margin-right: 1rem;
      }
    }
  }
}

@media (min-width: $tablet-small) {
  .landing-property-card {
    .profits {
      justify-content: flex-start;

      div:nth-child(1) {
        margin-right: 2rem;
      }
    }
  }
}

@media (min-width: $tablet-medium) {
  .landing-property-card {
    font-size: 1rem;
  }
}
  
@media (min-width: $laptop-small) {
  .landing-properties {
    flex-direction: row;
    width: auto;
  }

  .landing-property-card {
    width: 20rem;
    flex-grow: 0;
    margin: 0 14px;

    .profits {
      justify-content: space-between;
    }
  }
}

.landing-comments {
  display: flex;
  margin-top: -70px;
  flex-direction: column;
  width: 100%;

  @media (min-width: $tablet-small) {
    width: auto;
  }

  @media (min-width: $laptop-small) {
    flex-direction: row;
  }
}

.landing-card {

  .landing-card-image {
    width: 100%;
    height: 30rem;
    background-size: cover;
    background-position: center;
  }

  .landing-card-inner {
    width: 100%;

    p {
      font-family: "Gotham HTF";
    }

    h3 {
      font-family: TT Norms;
      font-size: 1rem;
      font-weight: bold;
      margin-bottom: 0;
    }
  }

  @media (min-width: $tablet-small) {
    .landing-card-image {
      width: 35rem;
    }

    .landing-card-inner {
      width: 28rem;
    }  
  }

  &:not(.single) {
    position: relative;
    margin: 2rem 0;

    .landing-card-inner {
      min-height: 16.5rem;
      background: #FFFFFF;
      box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.15);
      border-radius: 6px;
      padding: 1.8rem;

      p {
        color: #033863;
        opacity: 0.6;
        font-size: 1.1rem;
      }

      h3 {
        color: #033863;
        font-size: 1.2rem;
      }
    }
    
    .landing-card-label {
      margin-top: -1rem;
    }

    @media (min-width: $tablet-small) {
      margin: 4rem 0 7rem 0;

      .landing-card-inner {
        position: absolute;
        top: 19rem;
        left: 50%;
        transform: translateX(-50%);
      }

      .landing-card-label {
        margin-top: 0;
      }
    }

    @media (min-width: $laptop-small) {
      margin: 0 1.85rem;
    }
  }

  &.single {
    margin: 0;
    width: 100%;
    display: flex;
    justify-content: center;
    flex-direction: column;

    .landing-card-image {
      filter: drop-shadow(0px 4px 20px rgba(0, 0, 0, 0.15));
    }

    .landing-card-label {
      display: flex;
      align-items: center;
    }

    p {
      font-size: 1.2rem;
      line-height: 2.05rem;
      margin-top: 2rem;
    }

    &::after {
      font-family: "Gotham";
      content: "“";
      font-weight: 700;
      font-size: 23.5rem;
      line-height: 23.5rem;
      position: absolute;
      top: -10%;
      right: 0;
      color: #88DAFF;
    }

    @media (min-width: $tablet-small) {
      flex-direction: row;

      .landing-card-label {
        margin-left: 2rem;
        margin-right: 2rem;
      }

      p {
        margin-top: 0;
      }

      &::after {
        top: 0;
        left: 40%;
      }
    }

    @media (min-width: $tablet-medium) {
      &::after {
        top: -20%;
        left: 40%;
      }
    }

    @media (min-width: $tablet-large) {
      .landing-card-label {
        margin-left: 7.5rem;
        margin-right: 4rem;
      }

      &::after {
        top: 0;
        left: 40%;
      }
    }
  }
}