@import '../../style/variables';

.FileInput {
  display: none;
}

.reorderable {
  flex: 1;
  display: flex;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px;
}

.FileUploader {
  width: 100%;
  display: flex;
  flex-direction: column;

  >* {
    flex: 1;
  }

  .DropArea {
    flex: 1;
    background: $lightaccent;
    border-color: $lightaccent;
    text-align: center;
    padding-top: 24px;
    padding-bottom: 24px;
    border-radius: 5px;

    >div {
      font-weight: 600;
      opacity: 0.75;
    }
  }
}

.FilePreview {
  margin-top: 8px;
  margin-bottom: 8px;

  &.dragged {
    z-index: 2;
    opacity: 0.8;

    .RemoveImage {
      display: none;
    }
  }


  .PreviewContainer {
    padding-top: 3/4 * 100%;
    position: relative;
    cursor: pointer;

    .UploadedCheck {
      position: absolute;
      display: flex;
      flex-direction: column;
      align-content: stretch;
      align-items: center;
      width: 24px;
      height: 24px;
      border-radius: 50%;
      background: rgba(#fff, 0.8);
      right: 8px;
      bottom: 8px;
      z-index: 2;

      >svg {
        flex: 1;
      }
    }


    .ImageContainer {
      position: absolute;
      border-radius: 6px;
      border: 3px solid #CCE6FF;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      max-width: 100%;
      background-size: cover;
      background-repeat: no-repeat;
      background-position: center center;

      &.no-border {
        border: none;
      }
    }
  }

  &:first-child {
    .PreviewContainer {
      .ImageContainer::before {
        position: absolute;
        left: 0;
        bottom: 0;
        background: $primary;
        border-radius: 0 4px 0;
        padding: 0.33em 0.66em;
        color: #fff;
        content: "COVER";
        font-size: 12px;
        font-weight: 600;
        ;
      }

      .ImageContainer:not(.no-border) {
        border-color: $primary;
      }
    }
  }
}