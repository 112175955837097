.header-shadow {
  background-color: $lightaccent;
  color: rgba(50, 80, 120, 0.75);

  &:first-child {
    border-radius: $card-border-radius 0 0 0;
  }

  &:last-child {
    border-radius: 0 $card-border-radius 0 0;
  }
}