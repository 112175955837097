.simple-link {
  color: #1AB5FA;
  font-size: 1.2rem;
  text-decoration: underline;
  font-family: "Gotham";
  font-weight: 300;
  border: none;
  background: none;
  font-weight: 400;
  font-size: 1rem;
  text-transform: none;
  text-shadow: none;

  &:focus, &:hover, &:active {
    color: #0488c3;
    background: none;
  }
}

.link-button {
  background-color: transparent;
  border: none;
  cursor: pointer;
  text-decoration: underline;
  display: inline;
  margin: 0;
  padding: 0;
}

.link-button:hover,
.link-button:focus {
  text-decoration: none;
}