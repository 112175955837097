nav.landing-navbar {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  min-height: 16.5rem;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  z-index: 20;
  padding: 1.5rem;

  &.navbar-light {
    .nav-link {
      color: #FFFFFF;
    }

    .navbar-toggler {
      border-color: rgba(255, 255, 255, 0.2)
    }

    .navbar-collapse {
      background: rgba(3, 56, 99, 0.6);
    }
  }

  &.navbar-dark {
    background: linear-gradient(180deg, rgba(255, 255, 255, 0.6) 0%, rgba(255, 255, 255, 0) 100%);

    .navbar-toggler {
      border-color: rgba(3, 56, 99, 0.2)
    }
    
    &::before {
      content: "";
      position: absolute;
      top: 0;
      width: 100%;
      height: 12.9rem;
      background: linear-gradient(180deg, rgba(255, 255, 255, 0.6) 0%, rgba(255, 255, 255, 0) 100%);
      z-index: 6;
    }

    &::after {
      content: "";
      position: absolute;
      top: 0;
      width: 100%;
      height: 9rem;
      background: linear-gradient(180deg, #FFFFFF 0%, rgba(255, 255, 255, 0) 100%);
      z-index: 7;
    }

    .nav-link {
      color: #033863;
    }

    .navbar-collapse {
      background: rgba(255, 255, 255, 0.6);
    }
  }

  .navbar-inner {
    z-index: 10;
    display: flex;
    justify-content: space-between;
    max-width: 1178px;
    width: 100%;
  }

  .navbar-collapse {
    justify-content: flex-end;
    padding: 2rem;
    position: absolute;
    top: 70px;
    right: 1.5rem;
    backdrop-filter: blur(4px);
    border-radius: 6px;

    @media (min-width: $tablet-large) {
      padding: 0;
      position: unset;
      background: none !important;
      backdrop-filter: none;
    }
  }

  .navbar-nav {
    align-items: center;
    font-family: "TT Norms";
    font-weight: 500;

    .nav-link {
      padding-left: 0.95rem;
      padding-right: 0.95rem;
      font-size: 1rem;
      white-space: nowrap;
    }

    .landing-navbar-buttons {
      .btn {
        padding: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        font-family: inherit;
        font-weight: inherit;
        text-transform: uppercase;
        width: 8.75rem;
        height: 2.6rem;
        font-size: 0.9rem;
        margin-top: 1rem;
  
        &#signup,
        &#login {
          padding: 0;
        }
      }

      @media (min-width: $tablet-large) {
        display: flex;

        .btn {
          margin-top: 0;

          &:first-of-type {
            margin-left: 1.05rem;
          }
    
          &:not(:last-of-type) {
            margin-right: 0.8rem;
          }
        }
      }
    }
  }
}

.navbar-brand {
  display: inline-flex;
  max-height: 1.625rem;

  a {
    display: flex;
    align-items: center;
    margin: 0;
    padding: 0;
  }

  svg {
    width: 11rem;
  }
}

.navbar-toggler {
  max-height: 34px;
}