h1,
h2,
h3,
h4,
h5,
p {
  margin: 0;

  span {
    color: #1AB5FA;
  }
}

h1 {
  font-size: 3rem;
  line-height: 3rem;
  font-weight: bold;
  text-align: center;
}

h2 {
  font-weight: bold;
  font-size: 2.5rem;
  line-height: 3.75rem;
  letter-spacing: -0.03em;
  text-align: center;
}

h3 {
  font-size: 1.725rem;
  line-height: 2.5rem;
  letter-spacing: -0.03em;
  font-weight: 500;
  margin-bottom: 2.95rem;
  margin-top: 2.95rem;
  text-align: center;
}

h4 {
  font-size: 1.5rem;
  font-weight: 800;
}

h5 {
  font-size: 0.8rem;
  line-height: 0.95rem;
}

span,
h1,
h2,
h3,
h4,
h5,
p {
  font-family: inherit;
  color: #FFFFFF;
}
  
@media (min-width: $mobile-large) {
  h1 {
    font-size: 4rem;
    line-height: 4rem;
  }

  h2 {
    font-size: 3rem;
    line-height: 4.5rem;
  }

  h3 {
    line-height: 2rem;
  }
}

@media (min-width: $tablet-small) {
  h1 {
    font-size: 5rem;
    line-height: 5rem;
  }
}

@media (min-width: $tablet-medium) {
  h3 {
    margin-top: 1.25rem;
  }
}

@media (min-width: $tablet-large) {
  h1, h3 {
    text-align: left;
  }

  h2 {
    font-size: 3.5rem;
  }
}