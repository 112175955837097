.explanation-container {
  display: grid;
  justify-items: center;
  text-align: center;
  margin: 4rem 0;
  column-gap: 3.5rem;

  .explanation-image {
    margin-bottom: 2.35rem;
    margin-top: 3rem;

    &:nth-of-type(1) {
      order: 1;
    }
    
    &:nth-of-type(2) {
      order: 4;
    }
    
    &:nth-of-type(3) {
      order: 7;
    }
    
    &:nth-of-type(4) {
      order: 10;
    }
    
    &:nth-of-type(5) {
      order: 13;
    }
  }

  .explanation-title {
    display: flex;
    align-items: flex-end;
    margin-bottom: 1.25rem;
    color: #033863;
    font-size: 1.2rem;
    line-height: 1.3rem;

    &:nth-of-type(1) {
      order: 2;
    }
    
    &:nth-of-type(2) {
      order: 5;
    }
    
    &:nth-of-type(3) {
      order: 8;
    }
    
    &:nth-of-type(4) {
      order: 11;
    }
    
    &:nth-of-type(5) {
      order: 14;
    }
  }

  .explanation-text {
    color: #3B4247;
    font-size: 1rem;
    line-height: 1.5rem;

    &:nth-of-type(1) {
      order: 3;
    }
    
    &:nth-of-type(2) {
      order: 6;
    }
    
    &:nth-of-type(3) {
      order: 9;
    }
    
    &:nth-of-type(4) {
      order: 12;
    }
    
    &:nth-of-type(5) {
      order: 15;
    }
  }

  @media (min-width: $laptop-small) {
    .explanation-image, .explanation-text, .explanation-title {
      order: unset !important;
    }

    &.grid-3 {
      grid-template-columns: repeat(3, 20rem);
    }

    &.grid-4 {
      grid-template-columns: repeat(4, 15rem);
    }
  }
}
