@import "../../style/variables";

.large-custom-switch {
  .custom-switch {
    padding-left: 30px;
  }
  .custom-control-label {
    font-size: 15px;
    font-weight: 500;
    text-transform: none;
  }
  .custom-control-input:checked ~ .custom-control-label {
    &:before {
      background-color: $darkerblue;
      border-color: $darkerblue;
    }

    &:after {
      border-color: $white;
      background-color: $darkerblue;
      transform: translateX(130px);
    }
  }

  .custom-control-input ~ .custom-control-label {
    &:before {
      background-color: $white;
      border: 2px solid $white;
      width: 160px;
      height: 30px;
      border-radius: 30px;
    }

    &:after {
      border: 4px solid $darkerblue;
      background-color: $white;
      width: 26px;
      height: 26px;
      border-radius: 26px;

      box-sizing: border-box;
    }
  }

  .custom-control-input:focus:not(:checked) ~ .custom-control-label::before {
    border: $white;
  }
}
