.landing-pager {
  position: fixed;
  display: flex;
  flex-direction: column;
  top: 50%;
  transform: translateY(-50%);
  z-index: 20;
  transition: left 0.4s;
  left: 26px;

  a {
    color: #FFFFFF;
  }

  .indicator-outer {
    display: flex;
    justify-content: center;
    width: 20px;
    height: 30px;
    opacity: 0.3;
    transition: opacity 0.3s ease-out;
    border: none;
    background: none;

    &:not(:last-child) {
      margin-bottom: 15px;
    }

    .indicator-line {
      transition: border-color 0.3s;
      height: 100%;
      width: 0;
      border-left: 3px solid #FFFFFF;
    }

    &:hover,
    &:focus {
      opacity: 0.6;
      outline: none;
    }

    &.active {
      opacity: 1;
    }
  }

  &.dark {
    .indicator-outer {
      opacity: 0.2;

      .indicator-line {
        border-color: #033863;
      }

      &:hover,
      &:focus {
        opacity: 0.5;
        outline: none;
      }

      &.active {
        opacity: 1;
      }
    }
  }
}