.landing-container {
  display: flex;
  align-items: center;
  position: relative;
  max-width: 1440px;
  margin: 0 auto;
  width: 100%;
  padding: 0 2rem;
  justify-content: center;

  @media (min-width: $tablet-large) {
    padding: 0 3rem;
  }

  @media (min-width: 1024px) {
    padding: 0;
    justify-content: unset;

    &.aside {
      padding: 5em 0;
    }
  }
}

.video-overlay {
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  position: absolute;
  transition: opacity 1s;
  background-size: cover;

  &.hide {
    opacity: 0;
  }
}

.video-background {
  background: #000;
  position: fixed;
  top: 0;
  width: 100vw;
  height: 100vh;
  z-index: -99;
  pointer-events: none;

  @media (hover: none) {
    display: none;
  }

  iframe {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    pointer-events: none;

    @media (min-aspect-ratio: 16/9) {
      height: 62vw;
      width: 100vw;
    }

    @media (max-aspect-ratio: 16/9) {
      width: 200.78vh;
      height: 100vh;
    }
  }
}

.popover {
  .popover-body {
    p {
      color: #243B5D;
    }
  }
}