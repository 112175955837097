.steps-grid {
  display: grid;
  grid-template-columns: 14rem 3rem 36rem;
  margin: 2rem 0;
}

.steps-container {
  display: flex;
  flex-direction: column;
  margin: 2rem 0;
}

.step {
  display: flex;
  flex-direction: column;
  align-items: center;

  &:last-child {
    .step-line {
      display: none;
    }
  }
}

.step-title {
  text-align: center;
  margin-bottom: 0;
  width: 14rem;
  margin-bottom: 1rem;
}

.step-text {
  font-size: 1.25rem;
  line-height: 2rem;
  letter-spacing: -0.01em;
  margin-bottom: 33px;
  text-align: center;
  width: 100%;
}

.step-icon {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 3rem;
  margin: 0 0.75rem;
  order: -1;
  margin-bottom: 1rem;

  .step-line {
    flex: 1;
    width: 0;
    border-left: 2px solid #1AB5FA;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    margin: 10px 0;
    display: none;
  }

  svg {
    transform: scale(0.8);
  }
}

@media (min-width: $tablet-small) {
  .step {
    flex-direction: row;
    align-items: unset;
  }

  .step-title {
    text-align: right;
  }

  .step-icon {
    order: unset;
    margin-bottom: 0;

    .step-line {
      display: block;
    }
  }

  .step-text {
    width: 25rem;
    text-align: left;
  }
}

@media (min-width: $tablet-medium) {
  .step-text {
    width: 25rem;
  }
}

@media (min-width: $laptop-small) {
  .step-text {
    width: 30rem;
  }
}

@media (min-width: $laptop-large) {
  .step-text {
    width: 36rem;
  }
}