.landing-search {
  position: relative;
  width: 41.7rem;
  height: 3.15rem;

  .form-control {
    width: 100%;
    height: 100%;
    padding-left: 3.5rem;
    font-size: 0.8rem;
    font-weight: 350;
    border-radius: 0;
    border: none;
  }

  >svg {
    width: 0.95rem;
    height: 0.95rem;
    position: absolute;
    left: 1.3rem;
    top: 50%;
    transform: translateY(-50%);
  }

  button.btn.btn-close {
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
    padding: 0;
    height: 100%;
    width: 3.5rem;
    background: none;
    border: none;

    svg {
      width: 0.95rem;
      height: 0.95rem;

      rect {
        transition: all 0.2s;
      }
    }

    &:hover,
    &:focus {
      rect {
        fill: #1AB5FA;
      }
    }
  }
}