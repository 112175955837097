.tiles {
  .tile-row {
    display: flex;
    align-items: center;
    flex-direction: column;
    margin-bottom: 2.5rem;
  }

  .tile-text {
    h3, p {
      text-align: center;
    }

    p {
      font-size: 1.25rem;
      opacity: 0.6;
    }
  }

  .tile-img {
    width: 100%;
    order: -1;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  @media (min-width: $laptop-small) {
    .tile-row {
      flex-direction: row;
      margin-bottom: 0;
    }

    .tile {
      width: 50%;
    }

    .tile-img {
      order: unset;
    }

    .tile-text {
      padding: 0 6.25rem;

      h3, p {
        text-align: left;
      }
    }
  }
}