@import "../../style/variables";

.top-properties {
  $buttons-offset: 8px;
  margin: 0 -15px;

  .next-prev-image {
    position: absolute;
    top: 50%;
    margin-top: -30px;

    &.next {
      right: $buttons-offset;
      margin-top: -23px;
    }

    &.prev {
      left: $buttons-offset;
    }
  }
}

.price-rent-switch {
  z-index: 10;
  position: absolute;
  bottom: 5px;
  left: 20px;
}

.filter-button {
  &.selected {
    border-color: #C0D9FE;
    color: $primary;
  }
  &.selecting {
    background-color: #E6EAF1;
  }
  &:hover:not(.selecting) {
    background-color: #F2F4F8;
  }
}
