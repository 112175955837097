.property-address {
  border: 1px solid rgba(5, 56, 97, 0.5);
  height: 3.125rem;
  position: relative;

  &.is-invalid {
    border-color: #fe646f;
  }

  .geosuggest {
    height: 100%;
  }

  .geosuggest__suggests {
    border-radius: 0;

    .geosuggest__item--active {
      span {
        color: #fff;
      }
    }
  }

  .geosuggest__input {
    height: 100%;
    border: none;
    font-size: 1rem;
    background: #FFFFFF;
    font-weight: 400;
    padding-left: 3.4375rem;
    display: flex;
    align-items: center;

    &::placeholder {
      text-align: center;
    }
  }

  .property-address-icon {
    position: absolute;
    left: 1.625rem;
    top: 50%;
    transform: translateY(-50%);
  }

  &~.invalid-feedback {
    margin-bottom: 0;
    font-size: 14px;
  }
}