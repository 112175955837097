@import "../../style/variables";

.send-message-block,
.participant-block {
  background-color: $lightaccent;
}

.send-message-block {
  border-top: solid 1px $darkaccent;
  position: sticky;
  bottom: 0;
  left: 0;
  right: 0;
}

.participant-block {
  border-bottom: solid 1px $darkaccent;
}