@import "../../style/variables";

$blur-size: 56px;

.PropertyViewContainer {
  background-color: #f0f2f7;

  .offset {
    background-color: #fff;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: 160px;
  }
}

.ListingCarouselContainer {
  .SliderSlides {
    margin-left: -0.5rem;
    margin-right: -0.5rem;
  }

  .LargeSlide {
    position: relative;
    padding-top: 417/810 * 100%;
    border-radius: 5px;
    overflow: hidden;

    .back-image {
      border-radius: 5px;
    }

    .Actions {
      position: absolute;
      top: 0;
      right: 0;
      border-radius: 0 5px 0 5px;
      display: flex;
      overflow: hidden;
      cursor: pointer;

      .Tour {
        background: $primary;
        font-size: 14px;
        line-height: 20px;
        vertical-align: middle;
        color: #fff;
        padding: 8px 14px;

        >* {
          vertical-align: middle;
          display: inline-block;
        }
      }

      .Expand {
        background: rgba(26, 34, 51, 0.7);

        >div {
          padding: 8px 10px;
          line-height: 16px;
        }
      }
    }
  }

  .ThumbnailSlide {
    border-radius: 3px;
    overflow: hidden;
    position: relative;
    padding-top: 122/189 * 100%;
    cursor: pointer;

    .back-image {
      border-radius: 3px;
    }
  }

  .back-image {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-size: cover;
    background-position: center center;

    &.faded {
      opacity: 0.1;
      z-index: -1;
      box-shadow: inset 0px (-2 * $blur-size) $blur-size (-$blur-size) rgba(255, 255, 255, 1);
    }
  }
}

.ListingSummaryFeatures {
  margin: 1em -0.5em;
  display: flex;
  justify-items: stretch;

  .ListingSummaryFeature {
    margin: 0.33em;
    padding: 0;
    flex: 1;
    flex-basis: auto;
    min-width: 60px;
    font-size: 12px;
    text-transform: uppercase;
  }
}

.ListingSummaryInfo {
  margin: 1em 0;
  display: flex;
  justify-items: stretch;

  .ListingSummaryBlock {
    vertical-align: middle;
    flex: 1;
    text-align: left;
    font-weight: 500;
    line-height: 24px;

    &:first-child {
      flex: 0;
    }

    &:nth-child(3) {
      flex: 0;
    }
  }
}

.ListingPlanSelection,
.ListingNeighbourhood,
.ListingInfo {
  margin: 1em 0;
}

.ListingNeighbourhood {
  .card {
    background: #ffffff;
    border: 1px solid #d7d7e0;
    box-sizing: border-box;
    border-radius: 5px;
  }
}

.ListingAmenity {
  font-size: 13px;
  font-weight: 500;
  vertical-align: middle;
}

.QuickAccessMenu {
  margin-top: 1em;
  padding: 1em;
  display: flex;
  width: 100%;

  a {
    flex: 0;
    margin: 0 12px;
    font-weight: 500;
    color: $secondary;
    font-size: 13px;
    white-space: nowrap;
  }

  &.sticky {
    padding: 1em;

    div {
      z-index: 2;
    }

    &:after {
      content: "";
      background-color: #fff;
      position: absolute;
      left: -999em;
      right: -999em;
      top: 0;
      bottom: 0;
      z-index: 0;
      box-shadow: 0px 4px 15px rgba(108, 111, 115, 0.17);
      border-top: #d7d7e0;
    }
  }
}

ul.FeatureList {
  padding: 0;
  margin: 0;
  list-style-type: none;

  &.two-column {
    @media (min-width: 768px) {
      column-count: 2;
    }

    li {
      break-inside: avoid-column;
    }
  }

  li {
    padding: 0;
    margin: 0 0 2px 0;
    display: flex;
    font-size: 1em;
    font-weight: normal;

    div:first-child {
      flex: 1;
    }

    div:last-child {
      flex: 0;
      white-space: nowrap;

      &.highlight {
        font-weight: 700;
      }
    }

    &.labelled {
      display: block;
      padding: 4px 0;
      border-bottom: 1px solid #d7d7e0;

      div:first-child {
        font-size: 12px;
        color: #61636a;
      }

      div:last-child {
        line-height: 22px;
        font-weight: 500;
      }
    }

    &.divider {
      height: 1px;
      background: #d7d7e0;
      margin: 6px 0;
    }
  }
}

.image-slider {
  height: 100%;
  padding: 24px;

  .image-slide {
    display: flex;
    flex-direction: column;

    &::before,
    &::after {
      flex: 1;
      content: "";
    }

    .image-slider-image {
      flex: 0;
      padding-top: 3/5 * 100%;
      position: relative;

      .next-prev-image {
        position: absolute;
        top: 50%;
        margin-top: -30px;

        &.next {
          right: -32px;
          margin-top: -24px;
        }

        &.prev {
          left: -32px;
        }
      }

      .back-image {
        overflow: hidden;
        border-radius: 16px;
        box-shadow: 0px 2px 6px #3b6477;
      }

      .zoom-image {
        z-index: 1;
        position: absolute;
        bottom: 8px;
        right: 8px;
      }
    }
  }
}

.listing-features {
  display: flex;

  .listing-feature {
    padding: 16px 8px;
    min-width: 100px;
    text-align: center;
    position: relative;

    p {
      text-transform: uppercase;
      font-size: small;
    }

    &::after {
      content: "";
      position: absolute;
      top: 20px;
      bottom: 20px;
      right: 0;
      border-right: solid $lightaccent 2px;
    }

    &:last-child::after {
      border-right: none;
    }
  }
}

.btn.amenity {
  border-width: 1px;
  border-color: #cce6ff;
  font-weight: 300;
  font-size: 12;
  background: #fff;
  color: $secondary;

  &.selected {
    background-color: rgba(#cce6ff, 0.75);
  }
}

.SchoolScore {
  width: 64px;
  height: 64px;
  border-radius: 32px;
  display: flex;
  justify-content: center;

  background: #eef1f4;

  >div {
    display: flex;
    align-items: center;
  }

  h4 {
    margin: 0;
  }
}

.DashInfoBox {
  border: 1px dashed #d7d7e0;
  box-sizing: border-box;
  border-radius: 3px;
  text-align: center;
}

.OfferSummary {
  margin-top: 32px;
  background: #fafafc;
  border: 1px solid #caced8;
  box-sizing: border-box;
  border-radius: 5px;
  padding: 90px 160px;

  .offer-icon {
    display: inline-block;
    width: 32px;
    text-align: center;
    vertical-align: middle;
    margin-left: -4px;
  }

  .offer-info {
    vertical-align: middle;
  }
}

.PlanSelectionTimeline {
  height: 264px;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;

  border-top: solid 1px #d7d7e0;
  border-bottom: solid 1px #d7d7e0;

  .ruler {
    position: relative;
    width: 100%;
    margin: 20px auto;
    height: 30px;
    display: flex;
    border-top: solid 1px #d7d7e0;
    padding: 0 8%;

    .cm,
    .mm {
      position: relative;
      border-left: 1px solid #d7d7e0;
      height: 24px;
      width: 10%;
      flex: 1;
    }

    .cm {
      display: flex;

      &:last-of-type {
        flex: 0;
      }

      .label {
        position: absolute;
        top: 25px;
        font-size: 12px;
        transform: translateX(-50%);
        text-align: center;
        min-width: 100px;
      }
    }

    .mm {
      height: 12px;

      &:nth-of-type(1) {
        border: none;
      }
    }
  }
}

.PlanCard {
  top: 0;
  left: 0;
  position: absolute;
  padding: 0.66em;
  transform: translate(-50%, -110%);
  cursor: pointer;

  border-radius: 10px;

  background: #ffffff;
  box-shadow: 0px 4px 19px rgba(103, 116, 136, 0.2);

  p {
    margin: 0.66em 0 0;
  }

  p.small {
    font-size: 10px;
  }

  &.active {
    background: #1a2233;
    color: #fff;
    box-shadow: 0px 4px 19px rgba(22, 40, 68, 0.1);
    min-width: 160px;
    z-index: 1;

    h6 {
      white-space: nowrap;
    }
  }
}

.StickyContainer {
  align-items: flex-start;

  >div:last-child {
    flex-grow: 1;
    padding-right: 15px;
    padding-left: 15px;
  }
}

.react-tel-input {

  .flag-dropdown,
  .form-control {
    border-color: $secondary;
    background: #fff;

    &:focus,
    &:focus+.flag-dropdown {
      border-color: $primary;
    }
  }
}