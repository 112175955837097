.navbar {
  padding: 0;

  .nav-link {
    padding-top: 0.3em;
    padding-bottom: 0.3em;
  }
}

.AppMain {
  flex: 1;
  display: flex;
  flex-direction: column;

  .AppPage {
    margin-top: 58px;
    flex: 1;
    display: flex;
    flex-direction: column;
  }
}