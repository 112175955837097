.btn.btn-dojobrand {
  color: #FFFFFF;
  background: linear-gradient(348.12deg, #053861 -88.83%, rgba(5, 56, 97, 0) 113.51%), #1AB5FA;
  border: none;
  box-shadow: 1px 3px 2px rgba(0, 0, 0, 0.1);
  font-size: 0.9rem;

  &:hover,
  &:focus {
    color: #FFFFFF;
    background: linear-gradient(348.12deg, #053861 -88.83%, rgba(5, 56, 97, 0) 113.51%), #1590c5;
  }

  &:disabled:hover,
  &:disabled:focus {
    background: linear-gradient(348.12deg, #053861 -88.83%, rgba(5, 56, 97, 0) 113.51%), #1AB5FA;
  }

  &.btn-block {
    max-width: 100%;
  }
}

.btn-show-more {
  display: none;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 0;
  right: -15px;
  width: 6vw;
  // 3 rem - distance from the lower bottom of the card to the scrollbar; 6px - height of the scrollbar
  height: calc(100% - 3rem - 6px);
  min-width: 100px;
  max-width: 200px;
  border: none;
  background: linear-gradient(136.26deg, #FFFFFF -3.17%, rgba(26, 181, 250, 0.6) 108.79%);
  filter: drop-shadow(1px 3px 2px rgba(0, 0, 0, 0.1));
  color: #FFFFFF;
  opacity: 0.9;
  transition: opacity 0.35s;
  font-size: 1.2rem;

  &:hover {
    opacity: 1;
  }

  &:focus {
    outline: none;
  }

  span {
    margin-bottom: 1rem;
  }

  svg {
    height: 100px;
  }

  @media (min-width: 768px) {
    display: flex;
  }
}

.modal-controls {
  .single-control {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}