@import "../../style/variables";
$size: 10px;

.unread-message-marker {
  position: absolute;
  right: -5px;
  top: -1px;
  background: $primary;
  height: $size;
  width: $size;
  border-radius: $size / 2;
}

.DojoAvatar {
  position: relative;
  display: inline-block;
  vertical-align: middle;
  background-size: cover;
  background-position: center;
  border-radius: 50%;

  &.warning {
    border: 2px solid #FFF849;
    box-shadow: 0px 0px 10px #FFF849;

    & .attention {
      display: block;
    }
  }

  & .attention {
    display: none;
    position: absolute;
    right: -4px;
    bottom: -4px;
  }
}

.new-messages-container {
  display: inline;
  position: relative;
}