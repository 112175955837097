.landing-section {
  position: relative;
  display: flex;
  height: 100%;
  overflow: hidden;
  min-height: 100vh;
  padding: 5rem 0;

  @media (min-width: $tablet-large) {
    min-height: 50rem;
    padding: 3rem 0;
  }

  @media (min-width: $laptop-small) {
    padding: 3rem 0 5rem 0;

    &.nopadding {
      padding: 0 0 5rem 0;
    }
  }

  &:not(:first-of-type) {
    background: #FFFFFF;
  }

  .landing-scroll {
    position: absolute;
    left: 50%;
    bottom: 5px;
    width: 50px;
    height: 50px;
    transform: translateX(-50%);
    background: none;
    border: none;
    z-index: 10;
    display: flex;
    flex-direction: column;
    align-items: center;
    animation: bounce infinite;

    @media (min-width: $laptop-small) {
      bottom: 38px;
    }

    span {
      font-family: "Gotham";
      font-size: 0.7rem;
      color: #FFFFFF;
    }

    svg {
      position: absolute;
      bottom: 0;
      animation: bounce 1s infinite;
    }

    @keyframes bounce {
      0% {
        transform: translateY(0);
      }

      60% {
        transform: translateY(-10px);
      }

      100% {
        transform: translateY(0);
      }
    }
  }

  &.dark-controls {

    h2,
    h3 {
      color: #033863;
    }

    .landing-scroll {
      svg {
        path {
          fill: #033863;
        }
      }
    }
  }

  &.growable {
    height: auto;
    padding-top: 4rem;
    padding-bottom: 4rem;
    flex-grow: 1;
  }

  .landing-section-content {
    z-index: 10;
    display: flex;
    flex-direction: column;
    align-items: center;

    &.aside {
      display: flex;
      flex-direction: column;
      align-items: center;

      @media (min-width: 1024px) {
        margin-left: auto;
        width: 70%;
      }
    }

    &.centered {
      width: 100%;
      margin: 0;
      display: flex;
      flex-direction: column;
      align-items: center;
    }

    >p {
      text-align: center;

      @media (min-width: $tablet-large) {
        text-align: left;
      }
    }

    @media (min-width: 1024px) {
      margin-left: 184px;
      display: block;
    }
  }

  .landing-aside {
    height: 100%;
    width: 30%;
    position: absolute;
    top: 0;
    background-size: cover;
    background-position: top left;
    text-align: left;
    padding: 0 50px;
    display: none;
    flex-direction: column;
    justify-content: center;

    @media (min-width: 1024px) {
      display: flex;
    }

    &:after {
      position: absolute;
      content: "";
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }

    h2 {
      text-align: inherit;
      padding-bottom: 1.45rem;
    }

    p {
      font-family: "TT Norms";
      font-size: 1.3rem;
    }
  }

  .landing-aside-content {
    z-index: 10;
  }

  &::after {
    position: absolute;
    top: 0;
    left: 0;
    content: "";
    width: 100%;
    height: 100%;
  }
}