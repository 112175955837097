.btn {
  text-transform: uppercase;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 1.1rem;
  font-family: "Gotham";
  font-weight: 500;
  line-height: 1.5;
  border-radius: 6px;
  width: 100%;
  max-width: 30rem;
  height: 5rem;

  @media (min-width: $tablet-medium) {
    width: 15.5rem;
    max-width: unset;
    height: auto;
  }

  &:disabled {
    opacity: 0.5;
    cursor: default;
  }
}

.btn-outline-secondary {
  border: 1px solid #033863;
  font-weight: 500;

  &:focus {
    background-color: #243B5D;
    color: #FFFFFF;
  }
}

.btn-outline-light {
  border-width: 1px;
  font-size: 0.9rem;

  &:focus {
    color: #212529;
    background-color: #f8f9fa;
    border-color: #f8f9fa;
  }
}

.btn-dark {
  font-size: 0.9rem;
  background: #053861;
  box-shadow: 1px 3px 2px rgba(0, 0, 0, 0.1);
  font-weight: 500;
}

.inline-buttons {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 2.4rem 0 1.75rem 0;
  width: 100%;

  .btn {
    height: 5rem;
    font-size: 0.9rem;
    margin: 0 0.7rem 1rem 0;
  }

  @media (min-width: $tablet-medium) {
    flex-direction: row;
    justify-content: center;

    .btn {
      height: 3.25rem;
      margin: 0 0.7rem;
    }
  }
}

.tab-buttons {
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  display: flex;

  .btn.btn-tabs {
    color: #6290B6;
    font-size: 0.75rem;
    border-radius: 0;
    border-top: none;
    border-left: none;
    border-right: none;
    border-bottom-width: 3px;
    border-bottom-color: transparent;
    width: auto;
    white-space: nowrap;
    padding: 0.9rem;

    &.active {
      color: #1AB5FA;
      border-bottom: 3px solid #1AB5FA;
    }
  }
}