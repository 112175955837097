.login-form {
  width: 100%;
  max-width: 320px;
  display: flex;
  flex-direction: column;
  align-items: center;

  .forgot-password {
    margin-bottom: 1rem;
  }

  input {
    text-align: left !important;
    text-align-last: left !important;
  }
}