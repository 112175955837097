@import "../../../../components/landing/style/variables";

// BUYER LANDING PAGE

.landing-section {
  &#one-day {
    background-size: cover;
    background-position: top center;

    .video-overlay {
      background-image: url(./img/section1.jpg);
    }
    
    @media (hover: none) {
      background-image: url(./img/section1.jpg);
    }

    &::after {
      background: linear-gradient(90deg, rgba(0, 0, 0, 0.59) 1.82%, rgba(0, 0, 0, 0) 93.28%);
    }

    .btn {
      padding: 1rem 2.25rem;
    }
  }

  &#keys-first {
    background: #053861 url(./img/section2.jpg);
    background-size: cover;
    background-position: top center;

    p {
      font-size: 1.45rem;
      line-height: 2.7rem;
      margin-top: 3rem;

      @media (min-width: 992px) {
        width: 716px;
      }
    }

    &::after {
      background: linear-gradient(91.24deg, #053861 3.48%, rgba(5, 56, 97, 0) 72.23%), rgba(5, 56, 97, 0.3);
      backdrop-filter: blur(2px);
    }
  }

  &#how-dojo-works {
    background: #053861;

    .landing-aside {
      background-image: url(./img/aside1.jpg);
    }

    .btn-dojobrand {
      padding: 18px;
    }
  }

  &#our-goal {

    h4,
    p {
      font-family: "TT Norms";
    }

    h4 {
      color: #31343A;
      font-size: 1.2rem;
      line-height: 1.4rem;
    }

    p {
      color: #848B9D;
      font-size: 1.2rem;
      line-height: 1.4rem;
    }
  }

  &#you-belong-here {
    &:after {
      background: #D5E4EB;
      opacity: 0.5;
    }

    h3 {
      font-size: 1.2rem;
      line-height: 1.45rem;
      letter-spacing: -0.03em;
    }
  }

  &#comments {
    &:after {
      background: #033863;
      opacity: 0.7;
    }
  }

  &#smarter-choise {
    background: #053861;

    .landing-container {
      padding-top: 5em;
      padding-bottom: 0;

      .landing-section-content {
        height: 100%;
      }

      h2 {
        color: #1AB5FA;
        opacity: 0.8;
        margin-bottom: 3.55rem;
        margin-top: 1rem;

        span {
          color: #93d2f1;
        }
      }
    }

    .landing-aside {
      background-image: url(./img/aside2.jpg);

      &:after {
        position: absolute;
        content: "";
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: rgba(3, 56, 99, 0.5);
        backdrop-filter: blur(2px);
      }
    }

    @media (min-width: $tablet-medium) {
      padding: 3rem 0 0 0;
    }
  }

  &#day-one {
    height: 640px;

    h1 {
      color: #033863;
      font-size: 3.2rem;
    }
  }

  &#buyer-faq {
    background: #F7FAFB;
  }

  &#buyer-last {
    background: url(./img/section10.jpg);
    background-size: cover;
    background-position: center;
    height: 40rem;

    &::after {
      background: linear-gradient(91.24deg, #053861 3.48%, rgba(5, 56, 97, 0) 72.23%), rgba(5, 56, 97, 0.3);
    }
  }
}