@import "../../../style/variables";

.hero {
  height: 450px;
  background: url('./img/home_background.jpg') center;
  background-size: cover;
  .hero-search {
    max-width: 50vw;
    @media (max-width: 980px) {
      max-width: 90vw;
    }
  }
}

.left-align-slick > .slick-list > .slick-track {    
  margin-left:0;
}

.properties-category-name {
  font-weight: 500;
}
