// About page

.landing-section {
  &#about-head {
    padding: 0;
    min-height: 33.4375rem;
    background: #053861;
    
    h2 {
      margin-bottom: 2.8125rem;
    }
  }

  &#creation {
    background: #D5E4EB;

    p {
      color: #053861;
      text-align: center;
      font-size: 1.375rem;
      margin-top: 2.5rem;
      max-width: 65rem;
    }
  }

  &#commitment {
    h2, h3, p {
      color: #053861;
    }

    .tiles {
      margin-top: 3.5rem;
    }
  }

  &#about-cta {
    min-height: 31.25rem;

    h1 {
      color: #033863;
      font-size: 3.2rem;
    }
  }
}