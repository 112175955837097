@import "../../style/variables";
$size: 28px;
.messages-unread-badge {
  background: $primary;
  height: $size;
  width: $size;
  border-radius: $size / 2;
  color: $white;
  text-align: center;
  font-size: 14px;
  font-weight: 600;
  padding: 3px 0;
}
