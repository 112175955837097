@import "../style/variables";

html,
body {
  height: 100%;
}

body {
  display: flex;
  flex-direction: column;
}

#root {
  flex: 1;

  .App {
    min-height: 100%;
    display: flex;
    flex-direction: column;
  }
}

.dense {
  font-weight: 100;
}

.xsmall {
  font-size: 10px;
}

.vcenter-elements * {
  vertical-align: middle;
}

.vcenter-flex {
  display: flex;
  flex-direction: column;

  &::before,
  &::after {
    flex: 10;
    content: "";
  }
}

.pageSliderRight-enter {
  transform: translate3d(-100%, 0, 0);
}

.pageSliderRight-enter.pageSliderRight-enter-active {
  transform: translate3d(0, 0, 0);
  transition: all 500ms;
}

.pageSliderRight-leave {
  position: absolute;
  top: 0;
  left: 1.25rem;
  right: 1.25rem;
  transform: translate3d(0, 0, 0);
}

.pageSliderRight-leave.pageSliderRight-leave-active {
  transform: translate3d(110%, 0, 0);
  transition: all 500ms;
}

.pageSliderLeft-enter {
  transform: translate3d(100%, 0, 0);
}

.pageSliderLeft-enter.pageSliderLeft-enter-active {
  transform: translate3d(0, 0, 0);
  transition: all 500ms;
}

.pageSliderLeft-leave {
  position: absolute;
  top: 0;
  left: 1.25rem;
  right: 1.25rem;
  transform: translate3d(0, 0, 0);
}

.pageSliderLeft-leave.pageSliderLeft-leave-active {
  transform: translate3d(-110%, 0, 0);
  transition: all 500ms;
}

h6 span {
  vertical-align: middle;
}

.currency-input {
  border-left: none;
  padding-left: 0;
  text-align: right;
}

.input-group.is-invalid {
  .input-group-prepend>* {
    border-color: $danger;
  }
}

select.form-control.is-invalid {
  background-position: right calc(0.375em + 0.25rem + 0.5rem) center;
}

.react-datepicker-wrapper {
  width: 100%;

  &>div {
    position: relative;

    &::after {
      content: url('data:image/svg+xml; utf8, <svg width="19" height="18" viewBox="0 0 19 18" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M3.46875 1.64297L4.84609 1.64297L4.84609 0.996093C4.84609 0.619873 5.15108 0.314843 5.52734 0.314843C5.90361 0.314843 6.20859 0.619873 6.20859 0.996093L6.20859 1.64297L13.3461 1.64297L13.3461 0.996093C13.3461 0.619874 13.6511 0.314843 14.0273 0.314843C14.4036 0.314843 14.7086 0.619874 14.7086 0.996093L14.7086 1.64297L16.0859 1.64297C17.3771 1.64297 18.4273 2.69317 18.4273 3.98438L18.4273 15.2734C18.4273 16.5646 17.3771 17.6148 16.0859 17.6148L3.46875 17.6148C2.17754 17.6148 1.12734 16.5646 1.12734 15.2734L1.12734 3.98437C1.12734 2.69317 2.17755 1.64297 3.46875 1.64297ZM16.0859 3.00547L14.7086 3.00547L14.7086 3.38672C14.7086 3.76294 14.4036 4.06797 14.0273 4.06797C13.6511 4.06797 13.3461 3.76294 13.3461 3.38672L13.3461 3.00547L6.20859 3.00547L6.20859 3.38672C6.20859 3.76294 5.90361 4.06797 5.52734 4.06797C5.15108 4.06797 4.84609 3.76294 4.84609 3.38672L4.84609 3.00547L3.46875 3.00547C2.9291 3.00547 2.48984 3.44472 2.48984 3.98437L2.48984 5.09609L17.0648 5.09609L17.0648 3.98438C17.0648 3.44473 16.6256 3.00547 16.0859 3.00547ZM3.46875 16.2523L16.0859 16.2523C16.6256 16.2523 17.0648 15.8131 17.0648 15.2734L17.0648 6.45859L2.48984 6.45859L2.48984 15.2734C2.48984 15.8131 2.9291 16.2523 3.46875 16.2523Z" fill="%23053861" stroke="%23053861" stroke-width="0.3"/></svg>');
      position: absolute;
      right: 8px;
      top: 55%;
      width: 24px;
      transform: translateY(-50%);
    }
  }
}

.Box {
  border: 1px solid #D7DBE7;
  box-sizing: border-box;
  border-radius: 5px;
  padding: 10px 14px;


  label {
    text-transform: none;
    margin: 0;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 15px;
    color: #808997;
  }

  .form-control {
    border: none;
    width: 100%;
    margin: 0;
    padding: 0;
    font-style: normal;
    font-weight: 500;
    font-size: 1em;
    line-height: 19px;
    height: auto;

    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }

    /* Firefox */
    &[type=number] {
      -moz-appearance: textfield;
    }

  }
}

.cluster>div {
  color: #fff !important;
}

hr.light {
  border: none;
  border-bottom: rgba(255, 255, 255, 0.2) solid 1px;
}