footer.dojohome-footer {
  background: #033863;
  width: 100%;
  font-family: "Gotham";
  display: flex;
  flex-direction: column;

  a {
    color: #FFFFFF;
    font-size: 0.8rem;
  }

  .footer-links, .social-links, .dojohome-footer-inner { 
    display: flex; 
    flex-direction: column;
    align-items: center;
  }

  .social-links {
    a {
      margin-bottom: 1rem;
    }
  }

  .footer-links {
    margin-top: 1rem;

    a {
      margin-top: 1rem;
      white-space: nowrap;
    }
  }

  .dojohome-footer-inner {
    width: 100%;
    max-width: 1440px;
    margin: 0 auto;
    flex-wrap: wrap;
    justify-content: space-between;
  }

  .footer-up {
    font-weight: 500;
    padding: 2rem;

    .dojohome-footer-inner {
      &>a {
        margin-top: 1rem;
      }
    }

    svg {
      width: 10rem;
      height: 1.7rem;
    }
  }

  .footer-down {
    width: 100%;
    min-height: 4.8rem;
    bottom: 0;
    left: 0;
    display: flex;
    align-items: center;
    background: rgba(255, 255, 255, 0.1);
    padding: 1rem;
    
    span {
      margin-top: 1rem;
    }

    svg {
      height: 1.4rem;
    }
  }

  .footer-down-text {
    color: #FFFFFF;
    font-size: 0.55rem;
    opacity: 0.5;
    font-weight: 300;
  }

  @media (min-width: 992px) { 
    .footer-links, .social-links, .dojohome-footer-inner {  
      flex-direction: row;
    }

    .footer-links {
      margin-top: 0;
      justify-content: space-between;

      a {
        margin-top: 0;
      }

      a:not(:first-child) {
        margin-left: 1.5rem;
      }
    }

    .footer-up {
      padding: 2.2rem 5rem 1.75rem 4rem;

      .dojohome-footer-inner {
        a {
          margin-top: 0;
        }
      }
    }

    .footer-down {
      padding: 0 5rem 0 4.5rem;

      span {
        margin: 0 0 0 2.8rem;
      }
    }

    .social-links {
      a {
        margin-bottom: 0;
      }
    }

    #linkedin {
      margin-left: 1.7rem;
    }
  }
}