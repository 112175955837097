@import "../../../../components/landing/style/variables";
@import "../../../../components/landing/style/mixins.scss";

// SELLER LANDING PAGE

.landing-section {
  &#maximize-your-profits {
    background: linear-gradient(180deg, rgba(5, 56, 97, 0) 0%, #053861 100%);
    background-size: cover;
    text-align: center;

    &::before {
      @include fill-parent-div($background: linear-gradient(291.37deg, #053861 0%, #C2E4FF 100%));
      mix-blend-mode: multiply;
    }

    &::after {
      @include fill-parent-div($background: linear-gradient(163.61deg, rgba(255, 255, 255, 0.9) -7.3%, rgba(255, 255, 255, 0) 17.38%));
    }

    .navbar {
      height: auto;
      padding: 1.5rem;
      background: none;

      .navbar-inner {
        margin: 0;
      }

      &::before {
        content: none;
      }

      &::after {
        content: none;
      }
    }

    h1 {
      font-size: 3.25rem;
      line-height: 4rem;
    }

    h3 {
      text-align: center;
    }

    @media (min-width: $tablet-large) {
      .landing-section-content {
        .btn:first-child {
          margin-right: 1.75rem;
        }
      }
    }
  }

  &#what-is {
    text-align: center;

    .landing-section-content {
      max-width: 49.75rem;
      margin: 0 auto;
    }

    h2 {
      margin-bottom: 4.5rem;
    }

    p {
      color: #033863;
      opacity: 0.6;
      font-size: 1rem;
      margin-bottom: 1.5rem;
    }

    .btn {
      margin-top: 3.5rem;
    }
  }

  &#put-more-profit {
    background: rgba(213, 228, 235, 0.5);

    p {
      opacity: 0.6;
    }
  }

  &#radically-easy {
    background: #053861;

    .landing-aside {
      background-image: url(./img/aside1.jpg);
    }

    .steps-container {
      margin-bottom: 4.1rem;

      .step-line {
        padding-top: 1rem;
      }
    }

    @media (min-width: $tablet-large) {
      .landing-section-content {
        .btn:first-child {
          margin-right: 1.5rem;
        }

        .btn:nth-child(2) {
          width: 20rem;
        }
      }
    }
  }

  &#single-comment {
    background: linear-gradient(69.25deg, #4F7492 34.4%, #77A1C4 95.97%), #4F7492;
  }

  &#calculator {
    background: #F8F8F8;
  }

  &#your-home-belongs-here {
    background: rgba(213, 228, 235, 0.5);

    h3 {
      font-size: 1.2rem;
    }
  }

  &#perks {
    background: #0E4E82;

    &::after {
      background-image: url(./img/section8.jpg);
      background-size: cover;
      opacity: 0.2;
    }

    h4,
    p {
      color: #FFFFFF;
    }

    .explanation-container {
      margin-top: 4.4rem;
    }
  }

  &#see-how {
    background: #053861;

    .landing-container {
      padding-top: 5em;
      padding-bottom: 0;
    }

    .landing-section-content {
      height: 100%;

      h2 {
        margin-bottom: 3.55rem;
        margin-top: 4.35rem;
      }
    }

    .landing-aside {
      background-image: url(./img/aside2.jpg);

      h2 {
        margin-bottom: 0.75rem;
      }

      &::after {
        background: rgba(3, 56, 99, 0.6);
        backdrop-filter: blur(2px);
      }
    }

    @media (min-width: $tablet-medium) {
      padding: 3rem 0 0 0;
    }
  }

  &#seller-faq {
    background: rgba(224, 236, 240, 0.25);
  }

  &#seller-cta {
    min-height: 40rem;

    h1 {
      color: #033863;
      font-size: 3.2rem;
    }
  }

  &#seller-last {
    background: #4F7492;
  }
}