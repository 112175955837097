.slider {
  &.slider-horizontal {
    width: 100% !important;
    height: 24px !important;
  }

  .slider-track {
    position: absolute;
    background-color: #CCE6FF;
    background-image: none;
    box-shadow: none;
    border-radius: 4px;
  }

  .slider-selection {
    position: absolute;
    background-color: $primary;
    background-image: none;
    box-shadow: none;
    box-sizing: border-box;
    border-radius: 6px;
  }

  .slider-handle {
    position: absolute;
    top: 0;
    width: 24px;
    height: 24px;
    background-color: $primary;
    background-image: none;
  }
}

.explore-slider {
  .slick-arrow {
    svg {
      transition: 0.35s;

      &:hover {
        filter: drop-shadow(0px 3px 3px rgba(0, 0, 0, 0.4));
      }
    }
  }

  .slick-prev {
    left: -40px;
  }
}