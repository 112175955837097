.accordion {
  width: 100%;
  max-width: 1290px;
  margin: 3vh 0;

  .card {
    box-shadow: none;
    border-radius: 0;
    border-top: 1px solid #C6C6C6;
    background: none;

    &:last-of-type {
      border-bottom: 1px solid #C6C6C6;
    }

    >.collapse {
      border-bottom: none;
    }

    .card-header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      font-family: "Gotham HTF";
      font-weight: bold;
      font-size: 1rem;
      line-height: 1.1rem;
      padding: 1.5rem;
      transition-property: color, transform;
      transition-duration: 0.2s;

      svg {
        transition: inherit;
        width: 0.8rem;
      }

      &.expanded {
        color: #1AB5FA;

        svg {
          transform: rotate(180deg);

          path {
            stroke: #1AB5FA;
          }
        }
      }
    }

    .card-body {
      color: #848B9D;
      font-weight: 300;
      font-size: 1rem;
      padding: 0 1.5rem 1.5rem 1.5rem;
    }
  }

  @media (min-width: $tablet-small) {
    padding: 0 40px;
  }
}