.privacy-content, .terms-content {
  padding: 2rem 0;
  
  table {
    margin: 1rem 0;
    
    th, td {
      border-top: 1px solid rgba(0, 0, 0, 0.1);
    }

    tr:last-child {
      th, td {
        border-bottom: 1px solid rgba(0, 0, 0, 0.1);
      }
    }
  }

  h2, h3 {
    text-align: left;
    font-weight: 700;
  }

  h2 {
    margin-bottom: 1.5rem;
    padding-top: 1rem;
    padding-bottom: 1rem;
  }

  h3 {
    margin-top: 1.5rem;
    margin-bottom: 1rem;
    padding-top: 0.75rem;
    padding-bottom: 0.75rem;
  }

  p, td {
    font-size: 1rem;
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
    line-height: 1.75;
  }
}

