html {
  scroll-behavior: smooth;
  font-size: 80%;

  @media (min-width: 768px) {
    font-size: 100%;
  }

  @media (min-width: 1024px) {
    font-size: 90%;
  }

  @media (min-width: 1440px) {
    font-size: 100%;
  }
}

.navbar-brand img {
  height: 1.75rem;
  width: 10.25rem;
}

.CategoryList {
  .reorderable {
    flex-direction: column;

    >div {
      cursor: pointer;
      background: #fff;

    }

    .dragged {
      z-index: 10;
    }
  }
}