// Litera 4.3.1
// Bootswatch

//
// Color system
//

$white: #fff !default;
$gray-100: #f8f9fa !default;
$gray-200: #e9ecef !default;
$gray-300: #ddd !default;
$gray-400: #ced4da !default;
$gray-500: #adb5bd !default;
$gray-600: #868e96 !default;
$gray-650: #808997 !default;
$gray-700: #495057 !default;
$gray-800: #343a40 !default;
$gray-900: #212529 !default;
$black: #000 !default;

$blue: #1ab5fa !default;
$darkblue: #053861 !default;
$darkerblue: #243B5D !default;
$indigo: #6610f2 !default;
$purple: #6f42c1 !default;
$pink: #e83e8c !default;
$red: #E85B52 !default;
$orange: #fd7e14 !default;
$yellow: #f0ad4e !default;
$green: #7DCE82 !default;
$teal: #20c997 !default;
$cyan: #17a2b8 !default;

$lightaccent: #ebf2f5;
$darkaccent: #4f7492;

$primary: $blue !default;
$secondary: $darkerblue !default;
$success: $green !default;
$info: $blue !default;
$warning: $yellow !default;
$danger: $red !default;
$light: $gray-100 !default;
$dark: $gray-800 !default;

$yiq-contrasted-threshold: 190 !default;

$theme-colors: (lightgray: $gray-500,
  gray: $gray-650,
);

// Body

$body-color: $secondary !default;

// Fonts

$font-family-sans-serif: "Gotham",
Arial,
Helvetica,
sans-serif,
Roboto,
-apple-system,
BlinkMacSystemFont,
"Segoe UI",
"Noto Sans",
"Apple Color Emoji",
"Segoe UI Emoji",
"Segoe UI Symbol",
"Noto Color Emoji" !default;

$font-family-serif: "Roboto Slab",
Georgia,
serif !default;

$font-size-base: 1rem !default;
$font-family-base: $font-family-sans-serif;

$headings-font-family: $font-family-sans-serif;
$headings-font-weight: 500 !default;

// Tables

$table-accent-bg: $lightaccent !default;
$table-border-color: rgba(0, 0, 0, 0.1) !default;

// Buttons
$input-btn-focus-box-shadow: none;
$input-btn-focus-width: 0;

$input-btn-padding-y: 0.5rem !default;
$input-btn-padding-x: 1rem !default;

$btn-font-family: $font-family-sans-serif !default;

$btn-font-size-sm: 0.688rem !default;

$btn-border-radius-base: 10px;
$btn-border-radius-lg: 10px;
$btn-border-radius-sm: 10px;
// Forms

$input-border-color: $darkaccent !default;
$input-border-radius: 5px !default;
$input-placeholder-color: #98B7D3 !default;

$input-group-addon-bg: transparent !default;

$input-disabled-bg: $lightaccent !default;

// Lists


$list-group-border-color: $darkaccent !default;
// Navbar

$navbar-dark-color: rgba($white, .6) !default;
$navbar-dark-hover-color: $white !default;

$navbar-light-hover-color: $darkblue !default;
$navbar-light-active-color: $darkblue !default;

// Tooltips

$tooltip-font-size: 1em !default;
$tooltip-bg: #4F525C;
$tooltip-border-radius: 10px;

// Badges

$badge-border-radius: 5px;
$badge-font-weight: bold !default;
$badge-padding-y: 0.6em !default;
$badge-padding-x: 0.8em !default;

// Alerts

$alert-border-width: 0 !default;

// Cards
$card-cap-bg: $lightaccent;
$card-border-width: 0;
$card-border-radius: 5px;

// Modal

$modal-content-border-color: $darkaccent;
$modal-content-border-radius: 15px;

// hr

$hr-color: $darkaccent;

// Progress Bar
$progress-border-radius: 7px;
$progress-bg: $darkaccent;
$progress-bar-bg: $primary;
$progress-height: 20px;

// popover

//$popover-border-color: $darkaccent;
$popover-box-shadow: 0px 4px 19px rgba(22, 40, 68, 0.1);
$popover-border-color: transparent;
$popover-border-radius: 10px;
$popover-max-width: 480px;

// Grid

$container-max-widths: (sm: 540px,
  md: 720px,
  lg: 960px,
  xl: 1200px) !default;

$enable-responsive-font-sizes: true;