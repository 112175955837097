@mixin fill-parent {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

@mixin fill-parent-div($background) {
  @include fill-parent();
  content: "";
  background: $background;
}