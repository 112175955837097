.landing-calculator {
  width: 100%;
  max-width: 1290px;
  margin: 3rem 0 4rem;

  span,
  p {
    color: #053861;
  }

  .input-group-prepend {
    background: #fff;

    .input-group-text {
      border-color: #e5e5e5;
      border-radius: 0;
    }

    &+input {
      border-left: none;
      padding-left: 0;
    }
  }

  label {
    font-size: 0.8rem;

    .landing-tooltip {
      display: inline-block;
      margin-left: 0.4rem;
      padding: 2px;

      svg {
        width: 0.8rem;
        height: 0.8rem;
      }
    }
  }

  .alert {
    background-color: rgba(#1ab5fa, 0.1);

    &.alert-danger {
      background-color: #E85B52;

      p {
        color: #fff;
        font-size: 14px;
      }
    }

    div {
      font-size: 1.2rem;
      padding: 0.5em;
    }
  }

  label {
    text-transform: none;
  }

  .btn {
    height: auto;
    font-size: 0.8rem;
    font-weight: 600;
  }

  .form-control {
    position: relative;
    background: #ffffff;
    border: 1px solid #e5e5e5;
    border-radius: 0;
    font-size: 1rem;
    font-weight: 400;
    padding-top: 0.25rem;
    padding-bottom: 0.25rem;
    height: calc(2em + 1rem + 2px);

    &.is-invalid {
      border-color: #fe646f;

      &::after {
        content: "";
        position: absolute;
        right: 8px;
        top: 50%;
        transform: translateY(-50%);
      }
    }
  }

  .calculator-aside {
    .btn {
      width: 100%;
    }

    .form-control {
      font-size: 1rem;
    }
  }

  .calculator-main {
    margin-top: 2rem;

    .list-group {
      .list-group-item {
        padding: 1em;
        font-size: 1.3em;
        font-weight: 300;
        border: 1px solid rgba(0, 0, 0, 0.1);
        border-radius: 0;

        &+.list-group-item {
          border-top-width: 0;
        }

        &.summary {
          font-weight: 600;
        }
      }
    }

    .row.text-primary {
      div:first-child {
        h4 {
          font-size: 1.5em;
        }
      }

      svg {
        max-width: 100%;
      }

      div:nth-child(2), div:nth-child(3) {
        display: none;
      }
    }

    .list-group-item {
      .row {
        div:first-child {
          margin-bottom: 1rem;
          font-weight: 500;
        }

        div:nth-child(2) {
          margin-bottom: 0.5rem;

          &::before {
            content: "Traditional: ";
            margin-right: 1rem;
            opacity: 0.5;
            font-weight: 700;
          }
        }

        div:nth-child(3) {
          display: flex;
          align-items: center;
          
          &::before {
            content: url(../img/dojologo.svg);
            margin-right: 1rem;
            width: 7.7rem;
            height: 1.3rem;
            display: inline-block;
          }
        }
      }
    }
  }

  @media (min-width: $tablet-small) {
    padding: 0 40px;
  }

  @media (min-width: $tablet-large) {
    .calculator-main {
      margin-top: 0;
    }

    .card-body  {
      .row.text-primary {
        div:first-child h4, h4 {
          font-size: 1.2rem;
        }

        div:nth-child(2), div:nth-child(3) {
          display: block;
        }
      }
    }

    .list-group {
      .list-group-item {
        .row {
          div:first-child {
            margin-bottom: 0;
            font-weight: inherit;
          }

          div:nth-child(2), div:nth-child(3) {
            display: block;
            margin-bottom: 0;
            
            &::before {
              display: none;
            }
          }
        }
      }
    }
  }
}

@media (min-width: $laptop-small) {
  .row.text-primary {
    div:first-child {
      h4 {
        font-size: 1.5em;
      }
    }
    h4 {
      font-size: 1.5rem;
    }
  }
}