// Litera 4.3.1
// Bootswatch

// Variables ===================================================================
$primary-light: $darkaccent;

// Body ========================================================================
body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  background: $white;
}

// Navbar ======================================================================

.navbar {
  font-weight: bold;
  font-size: 1.1em;

  &.bg-dark {
    background-color: $darkblue !important;
  }

  &.bg-light {
    background-color: #fff !important;

    border-bottom: 1px solid rgba($primary-light, 0.33);

    &.navbar-fixed-top {
      border-width: 0 0 1px 0;
    }

    &.navbar-fixed-bottom {
      border-width: 1px 0 0 0;
    }
  }
}

.navbar-light .navbar-nav .nav-link {
  color: $darkblue;
  position: relative;
}

.dropdown-toggle.no-dropdown-toggle::after {
  content: none;
}

// Buttons =====================================================================

.btn {
  border-width: 2px;
  font-weight: 600;
  border-radius: 10px;

  &:not([class*="btn-outline"]) {
    text-shadow: 0px 2px 8px rgba(0, 0, 0, 0.25);
  }

  &.btn-rounded {
    border-radius: 32px;
  }

  &.btn-outline-default {
    border: 1px solid #D7DBE7;
    box-sizing: border-box;
    border-radius: 10px;
  }

  &.btn-default {
    background: #F0F2F7;
    border: 1px solid #D6D9E0;
    box-sizing: border-box;
    border-radius: 10px;
    text-shadow: none;
  }

  &.btn-dark {
    background: #353C4B;
    border: 1px solid #5C6475;
    box-sizing: border-box;
    text-shadow: none;
  }
}

.ScrollLinkButton {
  cursor: pointer;
  display: block;
  border-radius: 10px;
  width: 100%;
  background: #353C4B;
  border: 1px solid #5C6475;
  box-sizing: border-box;
  text-shadow: none;

}

.btn-group {
  .btn:not(:first-child) {
    border-left: none;
  }

}

.btn.btn-outline-secondary:hover svg {
  fill: #fff;
}

// Typography ==================================================================

blockquote {
  font-style: italic;
}

footer {
  font-size: $font-size-sm;
}

.lead {
  color: $gray-600;
  font-family: $font-family-sans-serif;
}

// Links
a:hover {
  text-decoration: none;
}

.link {
  color: $link-color;
  cursor: pointer;
}

.link:hover {
  color: $link-hover-color;
}

// Tables ======================================================================

table,
.table {
  font-size: $font-size-sm;

  &-primary,
  &-secondary,
  &-success,
  &-info,
  &-warning,
  &-danger {
    color: #fff;
  }
}

.table {
  &-primary {

    &,
    >th,
    >td {
      background-color: $primary;
    }
  }

  &-secondary {

    &,
    >th,
    >td {
      background-color: $secondary;
    }
  }

  &-light {

    &,
    >th,
    >td {
      background-color: $light;
    }
  }

  &-dark {

    &,
    >th,
    >td {
      background-color: $dark;
    }
  }

  &-success {

    &,
    >th,
    >td {
      background-color: $success;
    }
  }

  &-info {

    &,
    >th,
    >td {
      background-color: $info;
    }
  }

  &-danger {

    &,
    >th,
    >td {
      background-color: $danger;
    }
  }

  &-warning {

    &,
    >th,
    >td {
      background-color: $warning;
    }
  }

  &-active {

    &,
    >th,
    >td {
      background-color: $table-active-bg;
    }
  }

  &-hover {
    .table-primary:hover {

      &,
      >th,
      >td {
        background-color: darken($primary, 5%);
      }
    }

    .table-secondary:hover {

      &,
      >th,
      >td {
        background-color: darken($secondary, 5%);
      }
    }

    .table-light:hover {

      &,
      >th,
      >td {
        background-color: darken($light, 5%);
      }
    }

    .table-dark:hover {

      &,
      >th,
      >td {
        background-color: darken($dark, 5%);
      }
    }

    .table-success:hover {

      &,
      >th,
      >td {
        background-color: darken($success, 5%);
      }
    }

    .table-info:hover {

      &,
      >th,
      >td {
        background-color: darken($info, 5%);
      }
    }

    .table-danger:hover {

      &,
      >th,
      >td {
        background-color: darken($danger, 5%);
      }
    }

    .table-warning:hover {

      &,
      >th,
      >td {
        background-color: darken($warning, 5%);
      }
    }

    .table-active:hover {

      &,
      >th,
      >td {
        background-color: $table-active-bg;
      }
    }
  }

  &-striped tbody tr:nth-of-type(odd) {
    td:first-child {
      border-radius: 5px 0 0 5px;
    }

    td:last-child {
      border-radius: 0 5px 5px 0;
    }
  }
}

// Forms =======================================================================

label {
  max-width: 100%;
}

// Navs ========================================================================

.nav,
.breadcrumb,
.pagination {
  font-size: $font-size-sm;
}

.dropdown-menu {
  font-size: $font-size-sm;
}

// Indicators ==================================================================

.alert {
  color: $white;
  font-size: $font-size-sm;

  &,
  p {
    font-family: $font-family-sans-serif;
  }

  a,
  .alert-link {
    color: #fff;
    font-weight: normal;
    text-decoration: underline;
  }

  @each $color,
  $value in $theme-colors {
    &-#{$color} {
      @if $enable-gradients {
        background: $value linear-gradient(0deg, mix($body-bg, $value, 15%), $value) repeat-x;
      }

      @else {
        background-color: $value;
      }
    }
  }

  &-light {

    &,
    & a,
    & .alert-link {
      color: $body-color;
    }
  }
}

.badge {
  vertical-align: bottom;
}

// Progress bars ===============================================================

// Containers ==================================================================

// Cards

.card {
  box-shadow: 0px 4px 15px rgba(22, 40, 68, 0.12);

  &.no-shadow {
    box-shadow: none;

    &:hover {
      box-shadow: none;
    }
  }

  &:hover {
    box-shadow: 0px 4px 22px rgba(22, 40, 68, 0.35);
  }

  &:not(.tinted) {

    .card-header,
    .card-footer {
      background: transparent;
    }
  }

  &.disabled {
    opacity: 0.75;
    pointer-events: none;
  }

  &.selected {
    border: 5px solid $blue;
  }
}

.geosuggest {
  padding: 0;

  &.is-invalid {
    .geosuggest__input-wrapper {
      .geosuggest__input {
        border-color: #e85b52;
        padding-right: calc(1.5em + 1rem);
        background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23E85B52' viewBox='-2 -2 7 7'%3e%3cpath stroke='%23E85B52' d='M0 0l3 3m0-3L0 3'/%3e%3ccircle r='.5'/%3e%3ccircle cx='3' r='.5'/%3e%3ccircle cy='3' r='.5'/%3e%3ccircle cx='3' cy='3' r='.5'/%3e%3c/svg%3E");
        background-repeat: no-repeat;
        background-position: center right calc(0.375em + 0.25rem);
        background-size: calc(0.75em + 0.5rem) calc(0.75em + 0.5rem);
      }
    }
  }

  .geosuggest__input-wrapper {
    height: 100%;
    display: flex;
    justify-items: stretch;

    .geosuggest__input {
      border: none;
      flex: 1;
      border-radius: 5px;
      padding: 0.75rem 1rem;
      color: #495057;
      background-color: #fff;
      background-clip: padding-box;
      border: 1px solid $input-border-color;
      // border-radius: 0.25rem;
      transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;

      &::placeholder {
        color: $input-placeholder-color;
      }

      &:focus {
        color: #495057;
        background-color: #fff;
        border-color: #beecfb;
        outline: 0;
        box-shadow: 0 0 0 0.2rem rgba(70, 201, 244, 0.25);
      }
    }
  }

  .geosuggest__suggests-wrapper {
    z-index: 1;
    position: relative;

    .geosuggest__suggests {
      background: #ffffff;
      position: absolute;
      top: 4px;
      width: 100%;
      border: 1px solid #cce6ff;
      box-sizing: border-box;
      box-shadow: 0px 5px 20px rgba(58, 152, 238, 0.2);
      border-radius: 10px;
      margin: 0;
      padding: 0;
      list-style-type: none;
      overflow: hidden;

      &.geosuggest__suggests--hidden {
        display: none;
      }

      .geosuggest__item {
        padding: 0.66em;

        &.geosuggest__item--active {
          background: $primary-light;
        }
      }
    }
  }
}

.nav-tabs {
  border: none;

  .nav-item {
    border: none;

    &.nav-link,
    .nav-link {
      border: 0;
      font-weight: bold;
      font-size: 1em;
      background: none;

      &.active {
        border: 0;
        border-bottom: solid 2px $primary;
        color: $primary;
      }
    }
  }
}

.custom-switch {
  .custom-control-input:checked~.custom-control-label {
    &:before {
      background-color: $success;
      border: 1px solid $success;
    }

    &:after {
      background-color: $white;
    }
  }

  .custom-control-input~.custom-control-label {
    &:before {
      background-color: $primary;
      border: 1px solid $primary;
    }

    &:after {
      background-color: $white;
    }
  }
}

// Modal

.modal-content {}

hr {
  margin: 0.66rem 0;
  border-top: solid 1px $lightaccent;
}

.accordion {
  .card {
    .card-header {
      cursor: pointer;
    }

    &>.collapse {
      border-bottom: 1px solid $darkaccent;
    }
  }
}

.slick-slide {
  div:focus {
    outline: 0;
  }
}

.next-prev-image {
  z-index: 1;
  cursor: pointer;

  &.prev {
    transform: rotate(180deg);
    transform-origin: 32px;
  }
}

select.form-control {
  padding-left: 0.5rem;
  padding-right: 0.5rem;
}

.form-check-input {
  margin-top: 0.14em;
}

/*
 *  STYLE 3
 */

.h-scroll::-webkit-scrollbar-track {
  background-color: #E5EAF2;
}

.h-scroll::-webkit-scrollbar {
  height: 6px;
  background-color: #E5EAF2;
}

.h-scroll::-webkit-scrollbar-thumb {
  background-color: $primary;
  border-radius: 4px;
}

.dense.form-check label,
label.dense {
  font-weight: 300;
  text-transform: capitalize;
  font-size: 11px;
  white-space: nowrap;
  text-overflow: ellipsis;
}

label.dense {
  font-weight: 500;
  font-size: 13px;
}

.smallish {
  font-size: 90%;
}

@media (max-width: 576px) {
  .col-xs-12-narrow {
    flex: 0 0 90%;
    max-width: 90%;
  }
}

@media (min-width: 576px) {
  .col-sm-6-narrow {
    flex: 0 0 45%;
    max-width: 45%;
  }
}

@media (min-width: 992px) {
  .col-lg-4-narrow {
    flex: 0 0 30.5%;
    max-width: 30.5%;
  }
}

#dark-pending-info {
  .popover-body {
    background: #4F525C;
    border-color: #4F525C;

    >* {
      color: #fff;

    }
  }

  .arrow:after {
    border-bottom-color: #4F525C;
  }
}

.popover {
  min-width: 298px;

  .popover-body {
    box-shadow: 0px 4px 19px rgba(22, 40, 68, 0.1);
    border-radius: 10px;

  }

  .arrow {
    box-shadow: 0px 4px 19px rgba(22, 40, 68, 0.1);
    border-color: transparent;
  }
}

.modal-open #root {
  /* Add the blur effect */
  filter: blur(8px);
  -webkit-filter: blur(8px);
}

h1 {
  font-weight: 700;
}

h6 {
  font-size: 16px;

  &.subtitle {
    font-size: 1em;
  }
}

.normal {
  font-size: 14px;
}

.em {
  color: $primary;
  font-weight: normal;
}

input.muted-text {
  opacity: 0.5;
}

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type=number] {
  -moz-appearance: textfield;
}

.v-middle {
  >span {
    vertical-align: middle;
  }
}

.rc-slider {
  .rc-slider-track {
    background-color: #1AB5FA;
  }

  .rc-slider-handle {
    border: solid 1px #1AB5FA;
    width: 20px;
    height: 20px;
    margin-top: -8px;
  }
}