@font-face {
  font-family: "Gotham";
  src: url("../fonts/Gotham-Bold.otf") format('truetype');
  font-style: normal;
  font-weight: 700;
}

@font-face {
  font-family: "Gotham";
  src: url("../fonts/GothamMedium.otf") format('truetype');
  font-style: normal;
  font-weight: 500;
}

@font-face {
  font-family: "Gotham";
  src: url("../fonts/GothamRegular.otf") format('truetype');
  font-style: normal;
  font-weight: 400;
}

@font-face {
  font-family: "Gotham";
  src: url("../fonts/GothamLight.ttf") format('truetype');
  font-style: normal;
  font-weight: 300;
}

@font-face {
  font-family: "Gotham HTF";
  src: url("../fonts/Gotham-HTF.ttf") format('truetype');
  font-style: normal;
  font-weight: 400;
}

@font-face {
  font-family: "Gotham HTF";
  src: url("../fonts/Gotham-HTF-Bold.ttf") format('truetype');
  font-style: normal;
  font-weight: 700;
}

@font-face {
  font-family: "TT Norms";
  src: url("../fonts/TTNorms-Bold.otf") format('truetype');
  font-style: normal;
  font-weight: 700;
}

@font-face {
  font-family: "TT Norms";
  src: url("../fonts/TTNorms-Medium.otf") format('truetype');
  font-style: normal;
  font-weight: 500;
}

@font-face {
  font-family: "TT Norms";
  src: url("../fonts/TTNorms-Regular.otf") format('truetype');
  font-style: normal;
  font-weight: 400;
}

@font-face {
  font-family: "Inter";
  src: url("../fonts/Inter-Regular.ttf") format('truetype');
  font-style: normal;
  font-weight: 400
}

@font-face {
  font-family: "Inter";
  src: url("../fonts/Inter-Bold.ttf") format('truetype');
  font-style: normal;
  font-weight: 700
}