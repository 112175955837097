// FAQ page

.landing-section {
  &#faq-head {
    padding: 0;
    min-height: 22rem;
    background: linear-gradient(0deg, #053861, #053861), linear-gradient(0deg, #F7FAFB, #F7FAFB), linear-gradient(69.25deg, #4F7492 34.4%, #77A1C4 95.97%), linear-gradient(0deg, #053861, #053861);

    h1 {
      font-size: 2.65rem;
    }

    overflow: visible;
  }

  &#faq-body {
    min-height: auto;
    padding-top: 5rem;
    padding-bottom: 5rem;
    font-size: 1rem;

    .card-header {
      font-weight: 400;

      &.expanded {
        color: #033863;
        font-weight: 700;

        path {
          stroke: #243B5D;
        }
      }
    }
  }
}