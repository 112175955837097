.badge {
  top: 15px;
  position: absolute;
  font-size: 0.5em;
  border-radius: 5px;
  font-weight: 400;
  text-transform: uppercase;
  color: #FFFFFF;
}

.badge-info {
  left: 15px;
  padding: 5px 8px;
  background: #1AB5FA;
}

.badge-status {
  right: 15px;

  &.sold {
    background: #A2AEBA;
  }
}