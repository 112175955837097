.landing-comparison {
  display: flex;
  align-items: flex-start;
  flex-grow: 1;
  flex-direction: column;

  .landing-comparison-item {
    padding: 2rem 2.5rem 2rem 2.5rem;
    height: 100%;

    svg {
      width: 11.15rem;
      height: 1.7rem;
    }

    h3 {
      margin: 0 0 33px 0;
      font-size: 1rem;
    }

    ul {
      padding-left: 20px;

      li {
        margin-bottom: 1rem;
        font-size: 0.9rem;
      }
    }

    li,
    h3 {
      color: #8BAFC0;
    }

    &.active {
      background-color: #073E6B;

      li {
        list-style-image: url(../img/check.svg);
      }

      h3,
      li {
        color: #FFFFFF;
      }

      svg {
        fill: #FFFFFF;
      }
    }
  }

  @media (min-width: $tablet-medium) {
    flex-direction: row;

    .landing-comparison-item {
      padding: 4rem 2.5rem 8rem 2.5rem;
    }
  }
}