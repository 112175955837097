.loading-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex: 1;
}

.pulse-logo {
  animation: pulsate 1s ease-out;
  animation-iteration-count: infinite;
  -webkit-animation: pulsate 1s ease-out;
  -webkit-animation-iteration-count: infinite;
}

@keyframes pulsate {
  0% {
    transform: scale(0.9, 0.9);
    opacity: 0.7;
  }

  50% {
    transform: scale(1.2, 1.2);
    opacity: 0.7;
  }

  100% {
    transform: scale(0.9, 0.9);
    opacity: 0.7;
  }
}