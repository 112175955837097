@import "../landing/style/variables";

.modal.dojohome-modal {
  .modal-dialog {
    max-width: 560px;
  }

  .btn {
    font-weight: 500;
  }
  .modal-content {
    border-radius: 6px;
    box-shadow: 0px 20px 60px rgba(5, 56, 97, 0.5);
    border: none;

    h1,
    h2,
    h3,
    h4,
    h5,
    p,
    span {
      color: #053861;
      text-align: center;
    }

    h2 {
      font-size: 1.75rem;
      margin-top: 2.625rem;
      margin-bottom: 2.3125rem;
      line-height: 2rem;
    }

    h3 {
      font-size: 1.375rem;
      line-height: 1.5rem;
    }

    h4 {
      font-size: 1.25rem;
      font-weight: 500;
      margin-bottom: 1.375rem;
    }

    h5 {
      font-size: 1.125rem;
      line-height: 1.4375rem;
    }

    p {
      font-size: 1rem;
    }

    form {
      width: 100%;
      max-width: 420px;
      margin: auto;

      &.form-wide {
        max-width: 100%;
      }
    }

    .single-control {
      width: 100%;
      max-width: 310px;
      min-height: 65px;
      margin-left: auto;
      margin-right: auto;
      border-radius: 6px;
      font-size: 1rem;

      ~ .invalid-feedback {
        max-width: 310px;
        margin-left: auto;
        margin-right: auto;
      }
    }

    .modal-controls {
      width: 100%;
      display: flex;
      justify-content: center;
      text-align: center;
      margin-top: 0.75rem;
      margin-bottom: 1.5625rem;

      .btn:not(.single-control) {
        margin: 0 0.3125rem;
        width: 9.375rem;
        height: 3.125rem;
      }
    }

    .input-group {
      .input-group-prepend {
        border-right: 0;

        span {
          border-right: 0;
        }
      }

      input {
        border-left: none;
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
        background-color: transparent;
      }
    }

    .change-mode {
      display: flex;
      align-items: center;
      white-space: nowrap;
      font-weight: 500;
    }

    .muted-text {
      opacity: 0.5;
    }

    strong {
      color: #1ab5fa;
      display: inline-block;

      &.bordered {
        padding: 0.2rem;
        border: 1px solid #1ab5fa;
      }
    }
  }

  .modal-header {
    display: block;
    padding: 8px;
    border: none;

    .close {
      opacity: 0.5;
      display: flex;

      &:hover {
        opacity: 1;
      }

      span {
        color: rgb(5, 56, 97);
      }
    }
  }

  .modal-body {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 1rem 1rem 3.125rem 1rem;
    position: relative;
  }

  .modal-header-inner {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 9px;
    font-size: 0.6875rem;

    >svg {
      margin-right: 0.35rem;
    }
  }

  .progress {
    height: 10px;
    border-radius: 6px;
    background-color: rgba(26, 181, 250, 0.3);
    position: relative;
    margin-bottom: 9px;
  }

  .progress-bar {
    background-color: rgb(26, 181, 250);
    border-radius: 6px;
    min-width: 10px;
  }

  .progress-points {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 3px;

    .progress-point {
      width: 4px;
      height: 4px;
      background-color: #ffffff;
      border-radius: 100%;
    }
  }

  input[type="text"]:not(.geosuggest__input),
  input[type="email"],
  input[type="password"] {
    border: 1px solid #053861;
    font-size: 1rem;
  }

  input[type="range"] {
    margin-bottom: 3.5rem;
  }

  .btn-light {
    border: 1px solid #1ab5fa;
    color: #1ab5fa;
    background: none;
    text-shadow: none;

    &:hover {
      border: 1px solid #1ab5fa;
      color: #ffffff;
      background: #1ab5fa;
    }
  }

  .modal-skip {
    position: absolute;
    bottom: 24px;
    right: 24px;
    background: none;
    border: none;
    font-size: 0.875rem;
    color: #1ab5fa;

    svg {
      margin-left: 0.375rem;
    }
  }

  .geosuggest {
    .geosuggest__input {
      color: rgba(3, 56, 99, 0.5);
    }
  }

  .select-cards {
    display: flex;
    justify-content: center;
    margin: 1.5rem auto;
    flex-wrap: wrap;
    max-width: 500px;

    &.cards-4:not(.cards-small) {
      max-width: 19rem;
    }

    &.cards-small {
      margin: 1.5rem auto;

      .card {
        width: 5.3125rem;
        height: 6.25rem;
      }

      .card-body {
        padding: 0;
        justify-content: center;
      }

      .card-label {
        flex-grow: 0;
        margin-bottom: 0.625rem;
        font-size: 0.875rem;
      }

      .card-subtitle {
        font-size: 0.75rem;
      }
    }
  }

  .card {
    box-shadow: 0px 4px 12px rgba(3, 56, 99, 0.1);
    color: rgba(3, 56, 99, 0.7);
    height: 10.6875rem;
    width: 8.5rem;
    cursor: pointer;
    margin: 6px;

    &.active {
      color: #ffffff;
      background: #1ab5fa;

      .card-subtitle {
        color: inherit;
      }
    }

    .card-icon,
    .card-label {
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .card-icon {
      padding: 1rem 0;
    }

    .card-label {
      font-size: 0.75rem;
      flex-grow: 1;
      -webkit-touch-callout: none;
      /* iOS Safari */
      -webkit-user-select: none;
      /* Safari */
      -khtml-user-select: none;
      /* Konqueror HTML */
      -moz-user-select: none;
      /* Old versions of Firefox */
      -ms-user-select: none;
      /* Internet Explorer/Edge */
      user-select: none;
    }

    .card-subtitle {
      color: #1ab5fa;
    }

    .card-body {
      text-align: center;
      display: flex;
      flex-direction: column;
    }
  }

  .react-tel-input .form-control {
    width: 100%;
  }

  &.super-slim {
    .modal-dialog {
      max-width: 400px;

      @media (min-width: 420px) {
        margin: 1.75rem auto;
      }
    }
  }

  &.phone-modal {
    .modal-dialog {
      max-width: 475px;

      @media (min-width: 490px) {
        margin: 1.75rem auto;
      }
    }
  }

  &.type-modal {
    .select-cards {
      margin: 2.95rem auto;
    }
  }

  &.profile-modal {
    .modal-dialog {
      max-width: 475px;

      @media (min-width: 490px) {
        margin: 1.75rem auto;
      }
    }

    h2 {
      margin-top: 0;
    }
  }

  .profile-form {
    max-width: 310px;
    margin-bottom: 1rem;

    select {
      background-image: url(./img/arrow.svg);
      -webkit-appearance: none;
      background-repeat: no-repeat;
      background-position: 94% center;
      background-size: 0.75rem;
      padding-right: 2rem;
    }

    .col {
      display: flex;
      align-items: center;
    }

    span {
      font-size: 0.875rem;
      white-space: nowrap;
    }

    .react-tel-input {
      input {
        padding-left: 1rem;
      }
    }
  }

  .form-group {
    position: relative;
    width: 100%;

    input,
    select,
    .flag-dropdown {
      height: 50px;
      text-align: center;
      text-align-last: center;
      border: 1px solid rgba(5, 56, 97, 0.5);
    }

    .flag-dropdown {
      border-right: none;
    }

    &.firstname,
    &.lastname {
      display: inline-block;
      width: calc(50% - 0.5rem);
    }

    &.firstname {
      margin-right: 0.5rem;
    }

    &.lastname {
      margin-left: 0.5rem;
    }

    &.year {
      .invalid-feedback {
        margin-bottom: 0;
      }
    }

    &.check {
      &::after {
        content: url(./img/check-circle.svg);
        position: absolute;
        top: 25px;
        right: 8px;
        transform: translateY(-50%);
        width: 15px;
        height: 15px;
      }

      .password-show {
        right: 30px;
      }

      &.year {
        @media (min-width: 1440px) {
          &:after {
            right: 5px;
          }
        }

        select {
          background-position: 6% center;
          padding-left: 1.5rem;
        }
      }

      input {
        background-image: none;
      }

      input,
      select {
        padding-right: 2rem;
      }

      input,
      select,
      .flag-dropdown {
        border: 1px solid #053861;
      }

      .flag-dropdown {
        border-right: none;
      }

      @media (min-width: 400px) {
        &::after {
          width: 20px;
          height: 20px;
          right: 15px;
        }

        input,
        select {
          padding-right: 3.5rem;
        }

        &.year {
          select {
            padding-left: 2.5rem;
          }
        }
      }
    }

    &.single-control {
      .password-show {
        top: 33px;
      }

      &.error {
        .password-show {
          right: 1rem;
        }
      }
    }
  }

  .form-phone {
    text-align: center;

    h2,
    h4,
    p {
      margin: 1rem 0;
    }
  }

  .profile-completion {
    margin: 1rem 0;
    width: 100%;
    text-align: center;
  }

  .password-show {
    position: absolute;
    right: 0;
    top: 25px;
    width: auto;
    transform: translateY(-50%);
    background: none;
    opacity: 0.2;
    border: none;
    width: 56px;

    &:hover {
      opacity: 1;
    }

    &:focus {
      background: none;
    }
  }

  .is-invalid ~ .password-show {
    right: 15px;
  }
}

.code-input {
  margin: 1.25rem 0;

  input {
    width: 42px;
    height: 64px;
    text-align: center;
    margin: 0 5px;
    font-size: 2rem;
    border-radius: 6px;
    border: 1px solid #829bb0;
    caret-color: #1cb5fa;
    position: relative;

    &:focus {
      border-color: #1ab5fa;
      outline: none;
    }
  }
}

.invalid-feedback {
  margin-top: 0;
  margin-bottom: 20px;
}

.onboarding-calendar {
  max-width: 420px;
  max-height: 320px;
  padding: 1.25rem;
  border: 1px solid rgba(5, 56, 97, 0.2);
  box-sizing: border-box;
  border-radius: 6px;
  margin: 20px 0;

  @media (min-width: $tablet-small) {
    padding: 3.75rem;
  }

  .react-calendar__navigation {
    font-size: 1rem;
    margin-bottom: 17px;

    .react-calendar__navigation__arrow {
      display: flex;
      align-items: center;
    }

    .react-calendar__navigation__label {
      order: -1;
      margin-left: 0.65rem;
      padding: 0;
      display: flex;
      justify-content: flex-start;
      align-items: center;

      span {
        color: #1ab5fa;
        text-align: left;
      }
    }
  }

  .react-calendar__navigation__arrow,
  .react-calendar__month-view__weekdays__weekday {
    color: #1ab5fa;
  }

  .react-calendar__month-view__weekdays__weekday {
    text-align: center;
  }

  button {
    background: none;
    border: none;
  }

  abbr {
    text-decoration: none;
  }

  .react-calendar__tile {
    display: flex;
    justify-content: center;
    outline: none;
    padding: 6px;

    abbr {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 25px;
      height: 25px;
      border-radius: 100%;
      padding: 0;
      transition: all 0.25s;
    }

    &--active {
      abbr {
        color: #ffffff;
        background: #053861;
      }
    }

    @media (min-width: $tablet-small) {
      padding: 1px 6px;
    }
  }

  :focus {
    outline: none;
  }

  .code-validation {
    margin: 1.625rem 0;
    display: flex;
  }
}

.alert {
  p {
    color: inherit !important;
  }
}