@import "../../style/variables";

.PropertyCard {
  overflow: hidden;

  .property-rent {
    font-size: 1em;
    font-weight: 500;
    margin-bottom: 2px;
  }

  .property-address {
    font-weight: 600;
  }

  .property-details span {
    line-height: 2em;
  }

  .slick-next {
    z-index: 2;
    right: 10px;
  }

  .slick-prev {
    z-index: 2;
    left: 10px !important;
  }

  .SlideContainer,
  .CarouselContainer {
    position: relative;
  }
}